import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Chip,
  Box,
  Grid,
  Avatar,
  useTheme,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import CommentIcon from "@mui/icons-material/Comment";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PhoneIcon from "@mui/icons-material/Phone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import TimerIcon from "@mui/icons-material/Timer";
import WomanIcon from "@mui/icons-material/Woman";

import { useDispatch } from "react-redux";
import httpService from "../../services/http.service";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import "./RideDetails.css";
import RatingsSection from "../../components/RatingsSection/RatingsSection";
import { renderRatingStars } from "../../components/RatingsSection/renderRatingStarts";
import Swipe from "../../components/Swipes/Swipe";
import { toggleApproveBookingSwipe } from "../../redux/actions/toggleSwipe";
import ApproveBooking from "../ApproveBooking/ApproveBooking";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";

const tg = window.Telegram?.WebApp;

const StackAlert = ({ status, message }) => {
  return (
    <Stack spacing={2} sx={{ marginBottom: 2 }}>
      {status === "pending" ? (
        <Alert className="alert-error" severity="warning">
          Iltimos kutib turing. Haydovchi tasdiqlashi kutilmoqda
        </Alert>
      ) : (
        <></>
      )}
      {status === "confirmed" ? (
        <Alert className="alert-error" severity="success">
          Tasdiqlangan: Safar boshlanishi kitulmoqda
        </Alert>
      ) : (
        <></>
      )}
      {status === "rejected" ? (
        <Alert className="alert-error" severity="warning">
          Safarga qo'shilish haydovchi tomonidan rad qilingan
        </Alert>
      ) : (
        <></>
      )}
      {status === "cancelled" ? (
        <Alert className="alert-error" severity="warning">
          Bu safarga oldin qo'shilib kiyin bekor qilgansiz
        </Alert>
      ) : (
        <></>
      )}
    </Stack>
  );
};
const RideDetails = ({ params: { ride } }) => {
  console.log(ride);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(0);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showRatings, setShowRatings] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const theme = useTheme();
  const [rideStatus, setRideStatus] = useState(
    !ride || !ride.status ? "initial" : ride.status
  );
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const user_id = parseInt(params.get("user_id"));
    // console.log({ user_id });
    // console.log({ ride });
    setUserId(user_id);
    if (ride) {
      setRideStatus(ride.status);
    }
  }, [ride]);
  const handleBooking = async () => {
    dispatch(toggleApproveBookingSwipe());
  };
  const item34Style = {
    boxShadow:
      "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    height: "auto",
    "& .MuiChip-label": { whiteSpace: "normal" },
    mb: 1,
    fontSize: "16px",
  };
  const item35Style = { ...item34Style, backgroundColor: "initial" };
  const timeToHours = (time) => {
    let hours = Math.floor(time / 3600000);
    hours = hours ? `${hours} soat ` : "";

    let minutes = Math.floor((time % 3600000) / 60000);
    minutes = minutes ? `${minutes} minut` : "";
    return hours + minutes;
  };
  const handleApprove = async (searchDetails) => {
    // console.log("searchDetails", searchDetails);
    // const params = new URLSearchParams(window.location.search);
    // const user_id = parseFloat(params.get("user_id") ?? 2);
    // const telegram_id = parseFloat(params.get("telegram_id") ?? 2719158);
    // const user_id = 2;
    // const telegram_id = 2719158;

    try {
      const {
        departurePlace: {
          formatted_address: departure_place_address,
          location: departure_location,
        },
        arrivalPlace: {
          formatted_address: arrival_place_address,
          location: arrival_location,
        },
        departureDateTime: departure_datetime,
        frontSeat: front_seat,
        presenceOfWoman: presence_of_woman,
        seats,
        user_id,
        ...otherSearchDetails
      } = searchDetails;
      const { departure_dist, arrival_dist, ride_id } = ride;
      const res = await httpService.bookRide({
        ...otherSearchDetails,
        ride_id,
        passenger_id: user_id,
        departure_datetime,
        front_seat,
        seats: parseInt(seats),
        presence_of_woman,
        departure_place_address,
        departure_location,
        arrival_place_address,
        arrival_location,
        departure_dist,
        arrival_dist,
      });
      const { status } = res;
      // console.log("status", status);
      dispatch(toggleApproveBookingSwipe());
      setRideStatus(status);
      if (status === "confirmed" || status === "pending") {
        setTimeout(() => {
          tg.close();
        }, 1000);
      }
      setLoading(false);
    } catch (e) {
      await httpService.sendToSupport({
        message: "frontend bookRide " + e.message,
        Stack: e.stack ? e.stack : "",
      });
      dispatch(toggleApproveBookingSwipe());
      setError(e.message);
      setShowSnackbar(true);
      setLoading(false);
    }
  };
  const dateTimeFormat = (timestamp) => {
    const date = new Date(parseInt(timestamp));
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}.${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}.${date.getFullYear()}, ${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    return formattedDate;
  };
  return ride ? (
    <Card
      sx={{
        height: "auto",
        // overflowY: "scroll",
        m: 2,
        backgroundColor: theme.palette.background.default,
        backgroundImage: "none",
        boxShadow: "none",
      }}
    >
      <Snackbar
        open={!!showSnackbar}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          setShowSnackbar("");
        }}
      >
        {error ? (
          <Alert className="alert-error" severity="error">
            {error}
          </Alert>
        ) : (
          <></>
        )}
      </Snackbar>
      {rideStatus !== "initial" ? <StackAlert status={rideStatus} /> : <></>}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* <Chip sx={{height:'auto', '& .MuiChip-label': {whiteSpace:'normal'}}} icon={<LocationOnIcon />} label={`${departure_place_address} ➔ ${arrival_place_address}`} variant="outlined" color="primary" /> */}
          <Chip
            sx={item34Style}
            icon={<LocationOnIcon />}
            label={`${ride.departure_place_address} ➔ ${ride.arrival_place_address}`}
            color="primary"
          />
        </Grid>
        <Grid item xs={6}>
          <Chip
            icon={<AccessTimeIcon color="secondary" />}
            label={
              ride?.ride_start_mode === "firstPassenger"
                ? "Yo'lovchi vaqtiga qarab yuriladi"
                : dateTimeFormat(ride.departure_datetime)
            }
            sx={item35Style}
          />
        </Grid>
        <Grid item xs={6}>
          <Chip
            icon={<EventSeatIcon color="secondary" />}
            label={
              parseInt(ride.available_space) !== 0
                ? `${ride.available_space} ta bosh o'rindiq mavjud`
                : "Barcha o'rindiqlar band qilingan"
            }
            sx={{
              ...item35Style,
              // color: parseInt(ride.available_space) === 0 ? "red" : "initial",
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Chip
            icon={<PaymentsOutlinedIcon color="secondary" />}
            label={`${ride.fare} so'm`}
            sx={item35Style}
          />
        </Grid>
        <Grid item xs={6}>
          <Chip
            icon={<TimerIcon color="secondary" />}
            label={timeToHours(ride.duration)}
            sx={item35Style}
          />
        </Grid>
        <Grid item xs={6}>
          <Chip
            icon={<DirectionsCarIcon color="secondary" />}
            label={ride.car}
            sx={item35Style}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <Chip
            icon={<PhoneIcon color="secondary" />}
            label={ride.driver_phone_number}
            sx={item35Style}
            clickable
            component="a"
            href={`tel:${ride.driver_phone_number}`}
          />
        </Grid> */}
        {ride.front_seat && (
          <Grid item xs={6}>
            <Chip
              icon={<AirlineSeatReclineNormalIcon color="secondary" />}
              label={"Old o'rindiq band qilingan"}
              sx={item35Style}
            />
          </Grid>
        )}
        {ride.presence_of_woman && (
          <Grid item xs={6}>
            <Chip
              icon={<WomanIcon color="secondary" />}
              label={"Yo'lovchilar ichida ayollar bor"}
              sx={item35Style}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Chip
            icon={<CommentIcon color="secondary" />}
            label={ride.comment}
            sx={item35Style}
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ marginBottom: 4, marginTop: 2 }}
      >
        <Grid item>
          <Avatar src={ride.driverAvatar} sx={{ width: 50, height: 50 }} />
        </Grid>
        <Grid item xs>
          <Typography variant="h6" sx={{ lineHeight: 1 }} gutterBottom={false}>
            {ride.first_name}
          </Typography>
          {renderRatingStars(
            parseFloat(ride.average_rating),
            ride.rating_count
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="contained"
          startIcon={<CommentIcon />}
          endIcon={<StarBorderIcon />}
          onClick={() => setShowRatings(true)}
          sx={{
            backgroundColor: "#9c27b0", // Using a shade of blue for communication
            // "&:hover": {
            //   backgroundColor: "#1565c0",
            // },
            color: "white",
            fontWeight: "bold",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
            transition: "transform 0.2s ease-in-out",
            "&:hover": {
              transform: "scale(1.05)",
            },
            gap: 1, // Adds space between the icons and the text
          }}
        >
          Haydovchi sharhlar
        </Button>
      </Grid>
      <Box sx={{ marginTop: 4 }}>
        <LoadingButton
          onClick={handleBooking}
          loading={loading}
          variant="contained"
          color="primary"
          fullWidth
          disabled={
            userId === parseInt(ride.driver_id) ||
            rideStatus !== "initial" ||
            parseInt(ride.available_space) === 0
          }
        >
          Safarga qo‘shilish
        </LoadingButton>
        <Swipe
          selector={"isApproveBookingOpen"}
          toggleAction={!loading ? toggleApproveBookingSwipe : undefined}
          page={ApproveBooking}
          params={{ ride, handleApprove }}
        />
      </Box>

      {showRatings && (
        <div
          className="comments"
          style={{
            backgroundColor: tg ? "var(--tg-theme-bg-color)" : "white",
          }}
        >
          <RatingsSection
            user_id={ride.driver_id}
            userAvatar={ride.driverAvatar}
            close={() => setShowRatings(false)}
          />
        </div>
      )}
    </Card>
  ) : (
    <></>
  );
};

export default RideDetails;
